@import "../../../custom.scss";
/*common*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 900 !important;
  color: $secondary !important;
}

.container a{

  &::after{
    content: '\F1C5';
    font-family: bootstrap-icons !important;
    font-size: 10px;
    position: absolute;
    margin-left: 4px;
  }
}


.card {
  border-radius: 20px!important;
  border-color: $secondary !important;
  box-shadow: 10px 10px 0 $secondary;
}

.btn {
  font-size: 16px !important;
  border-radius: 20px!important;
}

span.tag {
  border-radius: 20px;
  background-color: $secondary;
  font-size: 12px;
  line-height: 18px;
  display: inline-flex;
  color: $white;
  padding: 5px 10px;
  margin-bottom: 10px;
}

.mdl-project__it {
  border-radius: 20px;
  border: 1px solid $secondary;
  box-shadow: 10px 10px 0px $secondary;


  &:hover{
    box-shadow: 12px 12px 0px $secondary;
  }

  span.tag{
    top: 10px;
    right: 0px;
  }

  img{
    height: 200px;
    object-fit: cover;
    width: 100%;
    border-bottom: 1px solid $secondary;

  }

  h3 {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 10px;
  }

  a{
    &::after{
      display: none;
    }
  }

}

.mdl-standard {
  padding: 80px 0 80px;
}

.mdl-intro {
  background-color: $white;
}

.c-title {
  top: -20px;
  background-color: $white;
  padding: 0 10px;
  left: 38px;
}

.btn.btn-link {
  padding: 0;
  color: $black !important;
  text-decoration-skip-ink: none;

  &:hover {
    text-decoration-thickness: 3px;

  }
}

.btn.btn-primary {
  border-radius: 30px !important;
  background-color: $secondary !important;
  border-color: $secondary;
  position: relative;
  z-index: 9;
  box-shadow: 5px 5px 0 $primary;
  min-width: 150px;
  
  &:hover {
    border-color: $secondary;
    box-shadow: 6px 6px 0 $primary;

  }

  &::after{
    display: none;
  }

}

.btn.disabled, .btn:disabled, fieldset:disabled .btn{
  border-color: $secondary!important;
}

.btn.btn-secondary {
  border-radius: 30px !important;
  background-color: $secondary !important;
  border-color: $secondary;
  position: relative;
  z-index: 9;
  
  &:hover {
    border-color: $secondary;
  }

  &::after{
    display: none;
  }

}

.btn.btn-outline-primary{
  border-color: $secondary;
  color: $secondary;

  &:hover{
    background-color: $secondary;
    border-color: $secondary;
    color: $white!important;
  }
}

.btn.btn-outline-secondary{
  border-color: $secondary;
  color: $secondary;

  &:hover{
    background-color: $secondary;
    border-color: $secondary;
    color: $white;

  }
}

.form-label {
  font-weight: 500;
}

.search-input {
  padding-right: 77px !important;
}

.form-control,
.form-select{
  border-radius: 20px !important;
  border-color: $secondary!important;
}

label a{
  color:$secondary;
  text-decoration-color: $secondary;
}