@import "../../custom.scss";

.mdl-project-single{
    &__info{
        border-radius: 20px;
        border: 1px solid $secondary;
        box-shadow: 10px 10px 0px $secondary;
        top: 100px;
        z-index: 9;

        strong{
            font-weight: 700;
            color: $secondary
        }
    }
    
    &__desc{
        img{
            border-radius: 20px;
            border: 1px solid $secondary;
        }
    }
}

.mdl-project-contributions{
    &__it{
        border-radius: 20px;
        border: 1px solid $secondary;
        background-color: #f7f9f9;
        box-shadow: 10px 10px 0 $secondary;
    }
}

.progress {
    position: relative;
    background-color: #E5EBEB;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .progress-bar {
    height: 100%;
    background-color: $secondary;
    border-radius: 10px;
    text-align: center;
    line-height: 20px;
    color: white;
    font-weight: bold;
  }
  
  .progress-text {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $primary;
  }
  