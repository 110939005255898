@import '../../../custom.scss';

.footer{
    padding-left: 20px;
    padding-right: 20px;

    .container{
        border-radius: 20px;
        border: 1px solid $primary;
        box-shadow: 10px 10px 0px $secondary;
        max-width: 100%;
        background-color: #fff;

        .col-md-3,
        .col-md-6{
            padding: 20px;
        }

        .col-md-3{
            border-right: 1px solid $primary;

            @media(width <= 767.98px){
                border-right: 0;
                border-bottom: 1px solid $primary;
            }

            h4{
                font-weight: 600!important;
                font-size: 20px;
            }
        }

        .col-md-6{
            padding-bottom: 10px;
        }

        h4, h5{
            font-weight: 600!important;
            font-size: 18px!important;
            text-transform: uppercase;
        }
    }
    
    img {
        max-width: 200px;
        width: 100%;
        margin-bottom: 20px;
    }
    
    ul {
        list-style: none;
        padding: 0;

        li {
            padding-right: 10px;

            a {
                color: black;
                text-decoration: none;
            }
        }
    }

    .social-icons {
        width: calc(100% + 40px);
        margin-left: -20px;
        border-top: 1px solid $primary;
        padding-top: 10px;
        padding-left: 20px;
        padding-right: 20px;

        a{
            color: $primary; 

            &::after{
                display: none;
            }
        }
    }
}