@import '../../../custom.scss';

.hero-section{
  display: flex;
  align-items: center;

  .col-md-6{
    padding: 100px 20px;

    @media(width <= 767.98px){
      padding-bottom: 30px;
      padding-top: 30px;

    }
  }

  h1{
    margin-bottom: 30px;
  }
}

.c-col{
  position: relative;
  display: flex;
  align-items: center;

  &::after, &::before{
    content: '';
    display: block;
    position: absolute;
    width: 100px;
    height: 100%;
    top: 0;
    background: rgb(229, 235, 235);
    z-index: 9;
    left: 0;
  }

  &::after{
    background: linear-gradient(90deg, rgba(229, 235, 235,1) 50%, rgba(229, 235, 235,0) 100%);
  }

  &::before{
    left: auto;
    right: 0;
    background: rgb(229, 235, 235);
    background: linear-gradient(-90deg, rgba(229, 235, 235,1) 50%, rgba(229, 235, 235,0) 100%);
  }
}

.marquee-item{
  background-color: rgba(3, 7, 18, 0.01);
  border-radius: 20px;
  padding: 20px;
  margin: 10px 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 340px;
  padding-left: 140px;
  position: relative;
  min-height: 160px;
  border: 1px solid $primary;
  box-shadow: 10px 10px 0px $secondary;

  &:hover{
    box-shadow: 12px 12px 0px $secondary;
  }

  a{
    &::after{
      display: none;
    }
  }

  img{
    max-width: 100px;
    border-radius: 50%;
    height: 100px;
    object-fit: cover;
    position: absolute;
    left: 20px;
    border: 1px solid $secondary;
  }

  h3{
    font-size: 16px;
    line-height: 20px;
  }

  span{
    font-size: 12px;
    line-height: 14px;
  }

  .btn{
    &.btn-link{
      font-weight: 300;
      text-decoration: none;
    }
  }
}