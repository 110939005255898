.mdl-hero-in{
    padding: 80px 0 80px;
    position: relative;
    //z-index: -1;

    a{
        color: #01403A;
    }
}

.title{
    display: inline-flex;
    position: relative;
    width: auto;

    &::after{
        content: "";
        width: 100%;
        height: 10px;
        background-color: #028b7e;
        display: block;
        position: absolute;
        bottom: -2px;
        left: 10px;
        z-index: -1;
    }

    &::before{
        content: "";
        width: 100%;
        height: 10px;
        background-color: #01403A;
        display: block;
        position: absolute;
        bottom: -7px;
        left: 15px;
        z-index: -1;
    }
}